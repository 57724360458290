import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { PageLayout } from '../components/PageLayout'
import { Hero } from '../components/Hero'
import { MapBlock } from '../components/MapBlock'

export const Head = () => (
    <>
        <title>Orientier BRPC Outdoor Recreation Website Proposal | Mapping</title>
        <meta name="description" content="Demo and development space for map/geo/GIS work" />
        <meta name="robots" content="noindex" />
    </>
)

const hero = <Hero heading="Mapping" subheading="A development and demo space for map/geo/GIS concepts" />

const MappingPage = () => (
    <PageLayout hero={hero}>
        <MapBlock />
        <Box width={{ base: '90vw', lg: '80vw' }} mx="auto" mb={12}>
            <Heading as="h2" size="md" mb={4}>
                Exhibit B: Demonstrate Local Filters Interacting with Map
            </Heading>
        </Box>
        <Box width={{ base: '90vw', lg: '80vw' }} mx="auto" mb={12}>
            <Heading as="h2" size="md" mb={4}>
                Exhibit C: Alternate Rendering for Inventory
            </Heading>
        </Box>
        <Box width={{ base: '90vw', lg: '80vw' }} mx="auto" mb={12}>
            <Heading as="h2" size="md" mb={4}>
                Exhibit D: Clustering with Intelligent Zoom
            </Heading>
        </Box>
        <Box width={{ base: '90vw', lg: '80vw' }} mx="auto" mb={12}>
            <Heading as="h2" size="md" mb={4}>
                Exhibit E: Custom Popups and Data Display
            </Heading>
        </Box>
        <Box width={{ base: '90vw', lg: '80vw' }} mx="auto" mb={12}>
            <Heading as="h2" size="md" mb={4}>
                Exhibit F: Additional Stylistic Variations
            </Heading>
        </Box>
        <Box width={{ base: '90vw', lg: '80vw' }} mx="auto" mb={12}>
            <Heading as="h2" size="md" mb={4}>
                Exhibit G: Merging Data Sources
            </Heading>
        </Box>
        <Box width={{ base: '90vw', lg: '80vw' }} mx="auto" mb={12}>
            <Heading as="h2" size="md" mb={4}>
                Exhibit H: Recreation-Type-Specific Maps
            </Heading>
        </Box>
        <Box width={{ base: '90vw', lg: '80vw' }} mx="auto" mb={12}>
            <Heading as="h2" size="md" mb={4}>
                Exhibit I: Navigation/Routing
            </Heading>
        </Box>
        <Box width={{ base: '90vw', lg: '80vw' }} mx="auto" mb={12}>
            <Heading as="h2" size="md" mb={4}>
                Exhibit J: Animation
            </Heading>
        </Box>
    </PageLayout>
)

export default MappingPage

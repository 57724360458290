import React, { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'

export const WebMapView = () => {
    const elementRef = useRef()

    useEffect((_) => {
        let cleanup
        // lazy load the module that loads the JSAPI
        // and initialize it
        import('../mappingHelpers/mapInitialization').then(
            (app) => (cleanup = app.initializeMapViewInContainer(elementRef.current))
        )
        return () => cleanup && cleanup()
    }, [])

    return <Box className="mapViewContainer" ref={elementRef} width="100%" height="80vh" />
}

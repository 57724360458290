import '@arcgis/core/assets/esri/themes/light/main.css'
import React from 'react'
import { Box, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { WebMapView } from './WebMapView'

export const MapBlock = () => (
    <Box className="mapViewWrapper" width={{ base: '90vw', lg: '80vw' }} mx="auto" mb={12}>
        <Heading as="h2" size="md" mb={4}>
            Exhibit A: Replicate BRPC Map Data and Integrate with Web App
        </Heading>
        <UnorderedList mb={8} fontSize="md" pl={4}>
            <ListItem>Recreate ArcGIS map using BRPC data</ListItem>
            <ListItem>Perform minor customizations</ListItem>
            <ListItem>Establish necessary configurations/credentials/connections</ListItem>
            <ListItem>
                Employ the ArcGIS JavaScript API to display the desired basemap and layers and customize the map
                interface at a basic level
            </ListItem>
        </UnorderedList>
        <WebMapView />
    </Box>
)
